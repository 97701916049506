import Vue from 'vue'
import './assets/style.css'
import App from './App.vue'
import store from './store/store';
import VueCarousel from 'vue-carousel';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/default.css'

Vue.component('VueSlider', VueSlider)
Vue.config.productionTip = false
Vue.use(VueCarousel);

Vue.directive('focus', {
  inserted: function (el) {
    el.focus();
  }
});

new Vue({
  store,
  render: h => h(App)
}).$mount('#app')
